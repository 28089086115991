
// @ts-nocheck
import { Card, Modal } from "@num/component-library";
import Vue from "vue";

import CompanyResultPrefix from "@/components/CompanyResult/Prefix.vue";
import Highlightable from "@/components/Highlightable.vue";
import Hours from "@/components/CompanyResult/Hours.vue";
import HoursStatus from "@/components/CompanyResult/HoursStatus.vue";
import IvrLookup from "@/components/IvrLookup.vue";

export default Vue.extend({
  name: "CompanyResultPhone",
  components: {
    Card,
    CompanyResultPrefix,
    Highlightable,
    Hours,
    HoursStatus,
    IvrLookup,
    Modal,
  },
  props: {
    filterInput: String,
    item: Object,
  },
  data() {
    return {
      closesAt: null,
      hoursModalShown: false,
      opensAt: null,
      tNumModalShown: false,
    };
  },
  computed: {
    href() {
      return this.item.action;
    },
    itemDescription() {
      return this.item.description;
    },
  },
});
