
import Vue from "vue";

export default Vue.extend({
  name: "CompanyResultPrefix",
  props: {
    item: Object,
  },
  computed: {
    icon() {
      if (this.item.method_type === "sms") return "chatbox-ellipses";
      else if (this.item.method_type === "telephone") return "call";
      else if (this.item.controller) return "desktop";
      return null;
    },
  },
});
