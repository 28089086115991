
// @ts-nocheck
import moment from "moment";
import Vue from "vue";
import { mapState } from "vuex";

import { isClosedAllDay, isOpenAllDay } from "@/utils/hours";

export default Vue.extend({
  name: "HoursStatus",
  props: {
    clickable: Boolean,
    item: Object,
    shrink: {
      default: true,
      type: Boolean,
    },
  },
  data() {
    return {
      closesAt: null,
      needsRefresh: false,
      opensAt: null,
    };
  },
  computed: {
    ...mapState(["timeNow"]),
    closesIn() {
      return this.closesAt?.diff(this.timeNow, "minutes") ?? null;
    },
    dynamicClass() {
      let dynamicClass = this.shrink
        ? "text-xs sm:text-sm md:text-base px-2 py-1 md:px-3 md:py-1.5"
        : "text-base px-3 py-1.5";

      dynamicClass += this.clickable ? " cursor-pointer" : " cursor-default";

      if (this.closesIn !== null && this.closesIn <= 5) {
        dynamicClass += ` bg-red-100 text-red-800 ${
          this.clickable ? "hover:bg-red-300" : ""
        }`;
      } else if (this.closesIn !== null && this.closesIn <= 60) {
        dynamicClass += ` bg-yellow-100 text-yellow-800 ${
          this.clickable ? "hover:bg-yellow-300" : ""
        }`;
      } else if (this.isOpen) {
        dynamicClass += ` bg-green-100 text-green-800 ${
          this.clickable ? "hover:bg-green-300" : ""
        }`;
      } else {
        dynamicClass += ` bg-gray-200 ${
          this.clickable ? "hover:bg-gray-300" : ""
        }`;
      }

      return dynamicClass;
    },
    isOpen() {
      return this.closesAt !== null;
    },
    opensIn() {
      return this.opensAt?.diff(this.timeNow, "minutes") ?? null;
    },
    statusText() {
      if (this.needsRefresh) {
        return "Refresh for latest";
      } else if (this.closesIn !== null && this.closesIn <= 60) {
        return `Closes in ${this.closesIn} min${this.closesIn > 1 ? "s" : ""}`;
      } else if (this.opensIn !== null && this.opensIn <= 60) {
        return `Opens in ${this.opensIn} min${this.closesIn > 1 ? "s" : ""}`;
      }
      // else if (this.closesAt !== null) {
      //   if (this.closesAt.isSame(this.timeNow, "day")) {
      //     return this.handleClosesOpensAt("closes");
      //   } else {
      //     return "Open all day"; // "Open";
      //   }
      // }
      else if (this.opensAt !== null) {
        return this.handleClosesOpensAt("opens");
      } else return this.isOpen ? "Open" : "Closed";
    },
  },
  watch: {
    timeNow: {
      handler(newVal) {
        if (newVal) this.updateTimings();
      },
      immediate: true,
    },
  },
  methods: {
    handleClosesOpensAt(opensOrCloses: string) {
      const timeAt = opensOrCloses === "opens" ? this.opensAt : this.closesAt;

      // have to use `endOf("day")` to make day calendar comparisons work,
      // otherwise it marks less than 24h as `0` day diff
      const daysFromNow = timeAt
        .clone()
        .endOf("day")
        .diff(this.timeNow.clone().endOf("day"), "day");
      const isMoreThanTomorrowFromNow = daysFromNow > 1;

      const isFirstHour = timeAt.hour() === 0;
      const isOnTheHour = timeAt.minute() === 0;
      const isMidnight = isFirstHour && isOnTheHour;

      return `${opensOrCloses === "opens" ? "Opens" : "Closes"} at ${
        isMidnight
          ? "midnight"
          : timeAt.format(
              `h${isOnTheHour ? "" : ":mm"}a ${
                isMoreThanTomorrowFromNow ? "ddd" : ""
              }`,
            )
      }`.trim();
    },
    isEndOfDay(end) {
      return end === "23:59:59" || end === "00:00:00";
    },
    updateTimings() {
      const { days } = this.item.hours;

      let dayIndex = -1;
      let isAlreadyClosed = false;
      let isAlreadyOpen = false;

      const daysNotInPast = days.filter((day) =>
        moment(day.date).isSameOrAfter(this.timeNow, "day"),
      );

      // if there's no days left today or in the future, the user needs to
      // refresh to get the latest data
      if (daysNotInPast.length === 0) {
        return (this.needsRefresh = true);
      }

      dayLoop: for (const day of daysNotInPast) {
        dayIndex++;
        const { available, date } = day;

        for (const availableString of available) {
          const [start, end] = availableString.split("-");
          const isToday = dayIndex === 0;
          const isLastDay = dayIndex === daysNotInPast.length - 1;
          let startTime = moment(`${date} ${start}`);
          let endTime = moment(`${date} ${end}`);

          if (isClosedAllDay(availableString)) {
            if (isAlreadyOpen) {
              this.closesAt = startTime;
              this.opensAt = null;
              break dayLoop; // exit out of looping through all days
            } else if (isLastDay && isAlreadyClosed) {
              // if its the last day, and we've been closed all week, set both times to null, we're not ever open
              this.closesAt = null;
              this.opensAt = null;
              break dayLoop; // exit out of looping through all days
            } else {
              // otherwise let the loop continue tomorrow to work out when it
              // opens
              isAlreadyClosed = true;
              break; // exit out of this available loop, go to next day
            }
          } else if (isOpenAllDay(availableString)) {
            if (isAlreadyClosed) {
              this.closesAt = null;
              this.opensAt = startTime;
              break dayLoop; // exit out of looping through all days
            } else if (isLastDay && isAlreadyOpen) {
              // if its the last day and we've been open all week set the
              // closing time to the last possible time, the end of this final
              // day
              this.closesAt = endTime;
              this.opensAt = null;
              break dayLoop; // exit out of looping through all days
            } else {
              // otherwise let the loop continue tomorrow to work out when it
              // closes
              isAlreadyOpen = true;
              break; // exit out of this available loop, go to next day
            }
          } else if (isToday) {
            if (this.timeNow.isBefore(startTime)) {
              // if not opened yet, set when it opens
              this.closesAt = null;
              this.opensAt = startTime;
              break dayLoop;
            } else if (this.timeNow.isBefore(endTime)) {
              // if not closed yet, set when it closes
              this.closesAt = endTime;
              this.opensAt = null;
              break dayLoop; // exit out of looping through all days
            } else {
              // otherwise, we're already closed, let the loop continue to work
              // out when we open again
              isAlreadyClosed = true;
              // return; // finish this available iteration, go to next
            }
          } else if (isLastDay) {
            if (isAlreadyOpen) {
              // if its the last day, and we've been open all previous days,
              // set the closing time
              this.closesAt = endTime;
              this.opensAt = null;
              break dayLoop; // exit out of looping through all days
            }
          } else {
            // if its not closed all day, or open all day, or today, or the last day...
            if (isAlreadyClosed) {
              // if we're already closed, set the opening time
              this.opensAt = startTime;
              this.closesAt = null;
              break dayLoop; // exit out of looping through all days
            } else if (isAlreadyOpen) {
              // else we're already open, set the closing time
              // if the start isn't open immediately, set it to be closed
              // immediately, otherwise use the end time
              const endToUse = start !== "00:00:00" ? "00:00:00" : end;
              endTime = moment(`${date} ${endToUse}`);
              this.closesAt = endTime;
              this.opensAt = null;
              break dayLoop; // exit out of looping through all days
            }
          }
        }
      }
    },
  },
});
