
// @ts-nocheck
import Vue from "vue";

import Highlightable from "@/components/Highlightable.vue";

import { getTelephoneCountFromLink } from "@/utils/utils";

export default Vue.extend({
  name: "CompanyResultLink",
  components: {
    Highlightable,
    CompanyResultPhoneNumbers: () =>
      import("@/components/CompanyResult/PhoneNumbers.vue"),
  },
  props: {
    expandAll: Boolean,
    filterInput: String,
    link: Object,
  },
  data() {
    return {
      expanded: false,
    };
  },
  computed: {
    telephoneCount() {
      return getTelephoneCountFromLink(this.link);
    },
  },
  watch: {
    expandAll: {
      handler(newVal) {
        this.expanded = newVal;
      },
      immediate: true,
    },
  },
  methods: {
    toggleExpanded() {
      this.expanded = !this.expanded;
    },
  },
});
