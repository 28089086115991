
// @ts-nocheck
import Vue from "vue";

import CompanyResultItem from "@/components/CompanyResult/Item.vue";

export default Vue.extend({
  name: "CompanyResultPhoneNumbers",
  components: {
    CompanyResultItem,
  },
  props: {
    contacts: Array,
    expandAll: Boolean,
    filterInput: String,
  },
});
