
// @ts-nocheck
import { Input } from "@num/component-library";
import Vue from "vue";

export default Vue.extend({
  name: "IvrResultFilter",
  components: {
    Input,
  },
  props: {
    value: String,
  },
  computed: {
    filterInput: {
      get() {
        return this.value;
      },
      async set(newVal) {
        this.$emit("input", newVal);
      },
    },
    inputClass() {
      let classes = ["p-1"];
      if (this.value !== "") classes.push("pr-7");
      return classes.join(" ");
    },
  },
  methods: {
    clearInput() {
      this.$emit("input", "");
    },
  },
});
