
// @ts-nocheck
import Vue from "vue";

import CompanyResultLink from "@/components/CompanyResult/Link.vue";
import CompanyResultPhone from "@/components/CompanyResult/Phone.vue";

export default Vue.extend({
  name: "CompanyResultItem",
  components: {
    CompanyResultLink,
    CompanyResultPhone,
  },
  props: {
    expandAll: Boolean,
    filterInput: String,
    item: Object,
  },
});
