
// @ts-nocheck
import { Modal, SkeletonLine, utilsTypeOf } from "@num/component-library";
import Vue from "vue";

import IvrResult from "@/components/IvrResult.vue";
import IvrResultFilter from "@/components/IvrResult/Filter.vue";

import { setupIvrApi } from "@/companyApi";

const ivrApi = setupIvrApi();
const showLoadingAfterMs = 200;
const { isEmptyObject } = utilsTypeOf;

export default Vue.extend({
  name: "IvrLookup",
  components: {
    IvrResult,
    IvrResultFilter,
    Modal,
    SkeletonLine,
  },
  props: {
    dial: String,
    itemDescription: String,
  },
  data() {
    return {
      filterInput: "",
      hasPassedLoadingWaitTime: false,
      ivrResult: null,
      loaded: false,
    };
  },
  computed: {
    ivrResultNotEmpty() {
      return this.ivrResult && !isEmptyObject(this.ivrResult);
    },
    modalShown() {
      return this.showLoading || this.ivrResultNotEmpty;
    },
    showLoading() {
      return !this.loaded && this.hasPassedLoadingWaitTime;
    },
  },
  created() {
    ivrApi
      .lookupPhoneNumber(this.dial)
      .then((result) => {
        this.ivrResult = result;
        if (isEmptyObject(result)) this.handleNoResult();
        this.loaded = true;
      })
      .catch((error) => {
        this.loaded = true;
        this.handleNoResult();
      });

    setTimeout(
      () => (this.hasPassedLoadingWaitTime = true),
      showLoadingAfterMs,
    );
  },
  methods: {
    handleNoResult() {
      window.open(`tel:${this.dial}`, "_self");
      this.$emit("close");
    },
    onModalClose() {
      this.resetValues();
      // wait for computed and watchers to trigger before unmounting component
      this.$nextTick(() => {
        this.$emit("close");
      });
    },
    resetValues() {
      this.ivrResult = null;
      this.loaded = false;
      this.hasPassedLoadingWaitTime = false;
    },
  },
});
