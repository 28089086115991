
// @ts-nocheck
import { Card } from "@num/component-library";
import Vue from "vue";

import IvrResultDialButton from "@/components/IvrResult/DialButton.vue";

import { getMenuTreeEndsFromItem } from "@/utils/utils";

export default Vue.extend({
  name: "IvrResult",
  components: {
    Card,
    IvrResultDialButton,
    IvrResultMenu: () => import("@/components/IvrResult/Menu.vue"),
  },
  props: {
    dial: String,
    filterInput: String,
    ivrResult: Object,
  },
  data() {
    return {
      filteredMenu: null,
    };
  },
  computed: {
    href() {
      return `tel:${this.dial}`;
    },
    treeEndCount() {
      let count = 0;

      if (this.filteredMenu) {
        for (const menuItem of this.filteredMenu) {
          try {
            if (menuItem.menu) count += getMenuTreeEndsFromItem(menuItem) || 0;
            else count += 1;
          } catch (error) {
            console.error(error);
          }
        }
      }

      return count;
    },
  },
  watch: {
    ivrResult: {
      handler(newVal) {
        if (!newVal) return;
        this.filterResults(newVal);
      },
      immediate: true,
    },
    filterInput() {
      this.filterResults(this.ivrResult);
    },
  },
  methods: {
    filterResults(ivrResult) {
      const { menu } = ivrResult;
      if (!menu) return;
      // must copy the contents to stop the original being manipulated
      // src: https://stackoverflow.com/a/28482916/827129
      const menuCopy = JSON.parse(JSON.stringify(menu));
      this.filteredMenu = this.handleMenu("", menuCopy);
    },
    handleMenu(searchLabel, menu) {
      return menu
        ?.map((c) => {
          return this.handleMenuItem(searchLabel, c);
        })
        .filter((c) => c !== null);
    },
    handleMenuItem(searchLabel, item) {
      if (item.menu) {
        searchLabel += ` ${item.description}`;

        if (item?.menu) {
          item.menu = this.handleMenu(searchLabel, item.menu);
        }

        return item;
      } else {
        if (item.description) searchLabel += ` ${item.description}`;
        const matchesFilter = this.searchWordsExistWithinTarget(
          this.filterInput,
          searchLabel,
        );
        return matchesFilter ? item : null;
      }
    },
    searchWordsExistWithinTarget(searchTerms, target) {
      if (searchTerms === "") return true;
      const searchTermsArray = searchTerms.split(" ");
      const allWordsExist = searchTermsArray.every((s) => {
        return target.toLowerCase().indexOf(s.toLowerCase()) > -1;
      });
      return allWordsExist;
    },
  },
});
