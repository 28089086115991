
// @ts-nocheck
import Vue from "vue";

import { escapeRegExp, textIsPartialPhoneNumber } from "@/utils/utils";

export default Vue.extend({
  name: "Highlightable",
  props: {
    hay: String,
    needle: String,
  },
  computed: {
    filteredNeedle() {
      return this.needle.replace(/[().-]/g, "");
    },
    highlightedTextArray() {
      return this.highlightNeedlesInHay(this.hay, this.filteredNeedle);
    },
    needleIsPhoneNumber() {
      return textIsPartialPhoneNumber(this.needle);
    },
  },
  methods: {
    highlightNeedlesInHay(hay, needlesStr) {
      let hayArray = [hay];

      // if no search, then just return without highlighting
      if (this.needle === "") {
        return hayArray;
      } else if (this.needleIsPhoneNumber) {
        // highlight consecutively
        const splitPerChar = needlesStr.replace(/\s/g, "").split("");
        hayArray = this.splitHighlightSequentially(splitPerChar, hayArray);
      } else {
        // highlight in any order
        const splitBySpace = needlesStr.trim().split(/\s+/);
        hayArray = this.splitHighlightAnyOrder(splitBySpace, hayArray);
      }

      return hayArray;
    },
    splitHighlightAnyOrder(needleArray, hayArray) {
      let updatedHayArray = hayArray;

      needleArray.forEach((n) => {
        // use () for capture and keeping the matched string, and `i` for case
        // insensitive matching
        const regex = new RegExp(`(${escapeRegExp(n)})`, "i");
        updatedHayArray = updatedHayArray
          .map((s, i) => {
            // if the index is odd then then this string is already highlighted,
            // so don't try to highlight within the highlight
            const isAlreadyHighlighted = i % 2 !== 0;
            return isAlreadyHighlighted ? [s] : s.split(regex);
          })
          .flat();
      });

      return updatedHayArray;
    },
    splitHighlightSequentially(needleArray, hayArray) {
      let updatedHayArray = hayArray;

      needleArray.forEach((needleItem) => {
        const lastHayItem = updatedHayArray[updatedHayArray.length - 1];
        const needleIndexInLastHayItem = lastHayItem.indexOf(needleItem);

        if (needleIndexInLastHayItem >= 0) {
          const before = lastHayItem.substr(0, needleIndexInLastHayItem);
          const match = needleItem;
          const after = lastHayItem.substr(needleIndexInLastHayItem + 1);
          // remove last needleItem
          updatedHayArray.pop();
          // and replace with the new split array
          updatedHayArray.push(before, match, after);
        }
      });

      return updatedHayArray;
    },
  },
});
