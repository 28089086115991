
// @ts-nocheck
import { Button } from "@num/component-library";
import Vue from "vue";

export default Vue.extend({
  name: "IvrResultTreeEnd",
  components: {
    Button,
  },
  props: {
    href: String,
  },
});
