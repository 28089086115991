
// @ts-nocheck
import moment from "moment";
import Vue from "vue";

import HoursStatus from "@/components/CompanyResult/HoursStatus.vue";

import { isClosedAllDay, isOpenAllDay } from "@/utils/hours";

export default Vue.extend({
  name: "Hours",
  components: {
    HoursStatus,
  },
  props: {
    href: String,
    item: Object,
  },
  data() {
    return {
      daysOfWeek: [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ],
    };
  },
  computed: {
    days() {
      return this.item?.hours?.days;
    },
    end() {
      return this.days.map((day) =>
        day.available.map((av) => (isClosedAllDay(av) ? "" : av.split("-")[1])),
      );
    },
    start() {
      return this.days.map((day) =>
        day.available.map((av) =>
          isClosedAllDay(av) ? "Closed" : av.split("-")[0],
        ),
      );
    },
  },
  methods: {
    formatDate(date) {
      const momentDate = moment(date);
      const numberedDayOfWeek = momentDate.weekday();
      const isToday = moment().isSame(momentDate, "day");
      const isTomorrow = moment().add(1, "days").isSame(momentDate, "day");
      if (isToday) return "Today";
      if (isTomorrow) return "Tomorrow";
      return this.daysOfWeek[numberedDayOfWeek];
    },
    formatTime(time) {
      if (time.endsWith(":00")) return time.slice(0, -3);
      return time;
    },
    formatTimeRange(timeRange) {
      if (isClosedAllDay(timeRange)) return "Closed";
      if (isOpenAllDay(timeRange)) return "Open all day";
      const windows = timeRange.split("-");
      return `${this.formatTime(windows[0])} &ndash; ${this.formatTime(
        windows[1],
      )}`;
    },
  },
});
